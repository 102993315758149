import React from "react"

import Layout from "../components/layout"
import AGB from "../components/agb"
import SEO from "../components/seo"

import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";

const IndexPage = ({intl}) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: "titleAGB" })} lang={intl.locale}/>
    <AGB></AGB>
  </Layout>
)

export default injectIntl(IndexPage)
